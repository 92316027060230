.skills__section{
    margin-bottom: 50px;
}

.skills__container{
    grid-template-columns: repeat(2, 475px);
    column-gap: 3rem;
    justify-content: center;
}

.skills__content{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    padding: 2rem 4rem;
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skills__title{
    font-size: var(--normal-font-size);
    font-weight: 800;
    text-align: left;
    margin-bottom: var(--mb-1-5);
}

.skills__box{
    display: flex;
    justify-content: center;
}

.skills__group{
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    justify-content: center;
}

.skills__data{
    display: flex;
    column-gap: 0.3rem;
    width: 110px;
}

.skills .bx-badge-check{
    font-size: 1rem;
    color: var(--title-color);
}

.skills__name{
    font-size: 13px;
    font-weight: var(--font-medium);
    line-height: 18px;
}

.skills__level{
    font-size: var(--tiny-font-size);
}

/* For large devices */
@media screen and (max-width: 992px) {
    .skills__container{
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .skills__container{
        grid-template-columns: 1.2fr
    }
}
  @media screen and (max-width: 576px) {
    .skills__group{
        display: flex;
        justify-content: space-between;
    }
    .skills__container{
        grid-template-columns: 1fr;
    }
    .skills__content{
        padding: 1.5rem;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 320px) {
    .skills__group{
        display: flex;
        justify-content:center;
    }
    .skills__box{
        column-gap: 1.25rem;
    }

    .skills__name{
        font-size: var(--small-font-size);
    }
  }

