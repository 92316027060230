.contact__container{
    grid-template-columns: repeat(1, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__title{
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info{
    display: grid;
    row-gap: 1rem;
    grid-template-columns:  repeat(2, 350px);
    column-gap: 2rem;
}

.contact__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0, 0, 0.1);
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 20px;
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
    transition: 0.3s;
}

.contact__card:hover{
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);
}

.contact__card-icon{
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data{
    font-size: var(--small-font-size)
}

.contact__card-title{
    font-weight: var(--font-medium);
}

.contact__card-data{
    display: block; 
    margin-bottom: var(--mb-0-75);
}

.contact__button{
    color: var(--text-color); 
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.contact__button:hover .contact__button-icon{
    transform: translate(0.25rem);
}

/*=============== BREAKPOINTS ===============*/
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .contact__info{
        grid-template-columns: repeat(1, 500px);
    }
  }
  
  @media screen and (max-width: 576px) {
    .contact__info{
        grid-template-columns: repeat(1, 400px);
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 450px) {
    .contact__info{
        grid-template-columns: repeat(1, 300px);
    }
  }

  @media screen and (max-width: 350px) {
    .contact__info{
        grid-template-columns: repeat(1, 250px);
    }
  }

