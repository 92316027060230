.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0,0,0,0.1); 
    border-radius: 5px;
}

.footer__container{
    padding: 0;
}

.footer__title,
.footer__link{
    color: var(--title-color);
}

.footer__title{
    text-align: center;
    margin-bottom: var(--mb-2);
}

.footer__link{
    color: gray;
}

.footer__link:hover{
    color: var(--title-color-dark);
}

.footer__copy{
    display: block;
    text-align: center;
    font-size: var(--smaller-font-size);
    color: gray;
    padding: 0.5rem;
}